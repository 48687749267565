<template>
    <div>
        <div class="bangding">{{$t('main.bindpage')}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        console.log(this.$route.query)
        
        this.axios.post('/auth/bindArtist',{type:2,code:this.$route.query.code,qr_code:this.$route.query.qr_code}).then((res)=>{
            if(res.data.code == 0){
                weui.alert(this.$t('main.bindsucc'),{buttons:[{label:this.$t('main.gotit'),onClick:()=>{
                    this.$router.push('/home_col')
                }}]})
                
            }else{
                weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
            }
        })
    }, 
    methods:{

    }
}
</script>

<style scoped>
.bangding{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>